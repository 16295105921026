<template>
  <Popup ref="popupRef" width="610px" :title="isEdit ? '编辑顾问信息' : '新建顾问'" confirm-text="确认" :confirmLoading="confirmLoading" @confirm="confirm" @close="initData">
    <el-form label-position="top">
      <el-form-item label="手机号" v-if="!isEdit">
        <el-input placeholder="请输入商务手机号" v-model="form.consultantMobile"></el-input>
      </el-form-item>
      <el-form-item label="头像：">
        <UploadImage :autoUpload="false" :imgUrl="form.avatar.img" @success="avatarUploadSuccess"></UploadImage>
      </el-form-item>
      <el-form-item label="商务姓名">
        <el-input placeholder="请输入商务姓名" v-model="form.consultantName"></el-input>
      </el-form-item>
      <el-form-item label="联系方式">
        <el-input placeholder="请输入联系方式" v-model="form.contactInfo"></el-input>
      </el-form-item>
      <el-form-item label="名片">
        <UploadImage :autoUpload="false" :imgUrl="form.contactImg.img" @success="cardUploadSuccess"></UploadImage>
      </el-form-item>
      <el-form-item label="权重">
        <el-input placeholder="请输入权重" v-model="form.weight"></el-input>
      </el-form-item>
    </el-form>
  </Popup>
</template>

<script>
import Popup from '@/components/Popup'
import UploadImage from '@/components/UploadImage'
import LoadImage from '@/components/LoadImage'
import { consultantManageApi } from '@/api/admin.js'
import uploadImageUtil from '@/utils/uploadImageUtil.js'
export default {
  components: {
    Popup,
    UploadImage,
    LoadImage
  },
  data() {
    return {
      form: {},
      isEdit: false,
      confirmLoading: false
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      this.form = {
        avatar: {
          img: '',
          file: null
        },
        consultantName: '',
        contactInfo: '',
        contactImg: {
          img: '',
          file: null
        },
        weight: '',
        consultantMobile: ''
      }
    },
    open(option = {}) {
      if(option.isEdit && option.detail) {
        this.isEdit = true
        this.uid = option.detail.uid
        this.form.consultantName = option.detail.consultantName
        this.form.contactInfo = option.detail.contactInfo
        this.form.avatar.img = option.detail.consultantPortrait
        this.form.contactImg.img = option.detail.contactCardImg
        this.form.weight = option.detail.weight
      }
      this.$refs.popupRef.open()
    },
    close() {
      this.initData()
      this.$refs.popupRef.close()
    },
    async confirm() {
      if(!this.form.consultantName) return this.$tips({message: '请填写姓名', type: 'warning'})
      if(!this.form.contactInfo) return this.$tips({message: '请填写联系方式', type: 'warning'})
      if(!this.form.avatar.img && !this.form.avatar.file) return this.$tips({message: '请上传个人头像', type: 'warning'})
      if(!this.form.contactImg.img && !this.form.contactImg.file) return this.$tips({message: '请上传名片', type: 'warning'})
      const formData = {
        act: 1,
        consultantInfo: {
          consultantName: this.form.consultantName,
          contactInfo: this.form.contactInfo,
          consultantPortrait: this.form.avatar.img,
          contactCardImg: this.form.contactImg.img,
          weight: this.form.weight
        }
      }
      if(this.isEdit) {
        Object.assign(formData.consultantInfo, {
          uid: this.uid,
        })
      } else {
        Object.assign(formData, {
          consultantMobile: this.form.consultantMobile
        })
      }
      try {
        const { uploadImageAsync } = await uploadImageUtil()
        if(this.form.avatar.file) {
          const res = await uploadImageAsync(this.form.avatar.file)
          formData.consultantInfo.consultantPortrait = res.ossUrl
        }
        if(this.form.contactImg.file) {
          const res = await uploadImageAsync(this.form.contactImg.file)
          formData.consultantInfo.contactCardImg = res.ossUrl
        }
      } catch (err) {
        this.$tips({message: '上传图片失败', type: 'error'})
        return
      }
      console.log('formData', formData)
      this.confirmLoading = true
      consultantManageApi(formData).then(res => {
        console.log('结果', res)
        this.confirmLoading = false
        this.$tips({message: res.msg, type: 'success'})
        this.$emit('update')
        this.close()
      }).catch(err => {
        this.confirmLoading = false
        this.$tips({message: err, type: 'error'})
      })
    },
    
    avatarUploadSuccess(e) {
      console.log('e',e)
      this.form.avatar.img = e.base64Url
      this.form.avatar.file = e.file
    },
    cardUploadSuccess(e) {
      console.log('e',e)
      this.form.contactImg.img = e.base64Url
      this.form.contactImg.file = e.file
    }
  }
}
</script>

<style lang="scss" scoped>

</style>